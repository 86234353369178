import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../../components/es/seo"

import MainImg from "./../../../images/main_bg.jpg"

import shape1 from "./../../../images/dec/1.png"
import shape2 from "./../../../images/dec/2.png"
import shape3 from "./../../../images/dec/3.png"
import shape4 from "./../../../images/dec/4.png"
import shape5 from "./../../../images/dec/5.png"
import shape6 from "./../../../images/dec/6.png"
import shape7 from "./../../../images/dec/7.png"
import shape8 from "./../../../images/dec/8.png"

import product1 from "./../../../images/es/moonamar-aceite-de-comino-negro-organico-250-ml-bio.png"

const CorokotovoUlje = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA - ACEITE DE COMINO NEGRO ORGÁNICO 250 ML (BIO)"
      description="BIO ACEITE IMMMUNO NATURA DE COMINO NEGRO – prensado en frio y sin tratamientos adicionales para mantener todas sus propiedades."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product1}
                alt="ACEITE DE COMINO NEGRO ORGÁNICO 250 ML (BIO)"
                title="ACEITE DE COMINO NEGRO ORGÁNICO 250 ML (BIO)"
              />
              <div className="_info">
                <div className="_inner">
                  <span>IMMMUNO NATURA</span>
                  <div className="title">
                    Aceite de comino negro ecológico (250 ml)
                  </div>
                  <div className="subtitle">Contenido: 250 ml</div>
                  <div className="price">19,90 €</div>
                </div>
                <div className="_buttonShop">
                  <Link to="/es/formulario-para-pedidos">PEDIDOS</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Descripción del producto</Tab>
                <Tab>Ingredientes y modo de empleo</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    <strong>ELABORACIÓN</strong>: Prensado solamente en frío.{" "}
                    <br /> <strong>INGREDIENTES</strong>: 100% aceite de comino
                    negro. <br /> <strong>CALIDAD</strong>: Ecológico, extra
                    virgen.
                  </p>
                  <p>
                    El aceite de comino negro Immmuno Natura tiene un sabor
                    suave obtenido de las semillas de nigella Sativa ,
                    especialmente seleccionadas, y de su cuidadoso prensado.{" "}
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td width="125">
                          <p>
                            <strong>Valor nutritivo</strong>
                          </p>
                        </td>
                        <td width="67">
                          <p>
                            <strong>por 100 g</strong>
                          </p>
                        </td>
                        <td width="81">
                          <p>
                            <strong>por 5 g</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Valor energético</p>
                        </td>
                        <td>
                          <p>
                            3.696 kJ
                            <br /> 899 kcal
                          </p>
                        </td>
                        <td>
                          <p>
                            184,8 kJ
                            <br /> 44,95 kcal
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Grasas
                            <br />
                            <em>saturadas</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            99,9 g<br /> 15,9 g
                          </p>
                        </td>
                        <td>
                          <p>
                            4,99 g<br /> 0,79 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Hidratos de carbono de
                            <br />
                            <em>los cuales azúcares</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            &lt; 0,1 g<br /> &lt; 0,5 g
                          </p>
                        </td>
                        <td>
                          <p>
                            &lt; 0,005 g<br /> &lt; 0,025 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Proteínas</p>
                        </td>
                        <td>
                          <p>0,1 g</p>
                        </td>
                        <td>
                          <p>0,005 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Sal</p>
                        </td>
                        <td>
                          <p>&lt; 0,0025 g</p>
                        </td>
                        <td>
                          <p>&lt; 0,000125 g</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <p>
                    El aceite de comino negro IMMMUNO NATURA se obtiene por
                    prensado en fr&#237;o y por la protecci&#243;n de los
                    ingredientes activos contenidos en la semilla, no se somete
                    a ning&#250;n procesamiento adicional.
                  </p>
                  <h3>Ingredientes:</h3>
                  <p>
                    El aceite de comino negro es rico en diferentes vitaminas,
                    minerales, prote&#237;nas, grasas no saturadas,
                    fitoesteroles, aceites esenciales as&#237; como algunas
                    sustancias que solo se encuentran en el comino negro
                    (Nigellona, Thimokinona, Nigelina).
                  </p>
                  <h3>Anwendung:</h3>
                  <p>
                    El aceite de comino negro se usa tradicionalmente
                    principalmente para regular el sistema inmunitario, tanto
                    como soporte para diversos tratamientos de alergia como para
                    fortalecer el sistema inmunitario. Para disfrutar de los
                    efectos positivos, el aceite generalmente se consume durante
                    al menos 8 semanas. Para aquellos que comen poco saludable,
                    se recomienda el aceite de comino negro como suplemento
                    nutricional.
                  </p>
                  <h3>Dosis recomendada:</h3>
                  <p>
                    <strong>Adultos: </strong>
                    2-3 veces al d&#237;a 5 g a la hora de las comidas
                    equilibradas. Como prevenci&#243;n, se toman 5 g al d&#237;a
                    para el desayuno.
                  </p>
                  <p>
                    <strong>Ni&#241;os: </strong>
                    1 cucharadita 2-3 veces al d&#237;a. Como prevenci&#243;n,
                    se toma 1 cucharadita al d&#237;a antes del desayuno.
                    <br />
                  </p>
                  <h3>Consejos:</h3>
                  <ul>
                    <li>La dosis recomendada no se debe sobrepasar.</li>
                    <li>
                      Los suplementos alimenticos no se tienen que utilizar como
                      sustitutivos de una alimentaci&#243;n completa y sana.
                    </li>
                    <li>Mantener fuera del alcance de los ni&#241;os.</li>
                    <li>
                      Mantener en fr&#237;o y fuera del alcance de la luz.
                    </li>
                  </ul>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default CorokotovoUlje
